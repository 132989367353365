import * as React from 'react';
import { NavItem, useNav } from '@lcifr/components/nav';
import { Anchor } from '@lcifr/components/anchor';
import { useOverlayTriggerState } from '@react-stately/overlays';
import { Button } from '@lcifr/components/button';
import { Stack } from '@lcifr/components/stack';
import { Box } from '@lcifr/components/box';
import { Wrap } from '@lcifr/components/wrap';
import { CenterY } from '@lcifr/components/center';
import { Burger, Close, Draw } from './MainNavDrawer.sx';
import { Inline } from '@lcifr/components/inline';
import { LangSwitch } from 'components/LangSwitch';
import { Logo } from 'components/Logo';
import { useTimeout, useWindowSize } from 'usehooks-ts';
import { alpha } from '@lcifr/theming';
import { useState } from 'react';

function CloseOnLargeScreens({ close }: { close: () => void }) {
  const { width } = useWindowSize();

  React.useEffect(() => {
    if (width >= 961) {
      close();
    }
  }, [width, close]);

  return null;
}

function CloseButton({ close }: { close: () => void }) {
  const [canClose, setCanClose] = React.useState(false);

  useTimeout(() => {
    setCanClose(true);
  }, 150);

  return (
    <Button
      variant="menu"
      onPress={canClose ? close : undefined}
      sx={{
        opacity: canClose ? 1 : 0.5,
        transition: 'opacity 150ms ease-in-out',
      }}
      disabled={!canClose}
      aria-label="Close Menu"
    >
      <Close name="close" />
    </Button>
  );
}

type MainNavDrawerProps = {
  items: NavItem[];
  button: React.ReactNode;
};

export function MainNavDrawer({ items, button }: MainNavDrawerProps) {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)
  //open, close, isOpen

  const legalNav = useNav('legal');

  return (
    <>
      <Button
        variant="menu"
        onPress={open}
        sx={{
          pointerEvents: 'initial',
          position: 'absolute',
          right: 0,
          top: 0,
          height: '100%',
          display: 'grid',
          placeItems: 'center',
        }}
        aria-label="Open Menu"
      >
        <Burger name="burger" />
      </Button>

      {isOpen ? (
        <Draw onClose={close} className="main-nav" wrapWidth="full"  role="dialog" tabIndex={-1}>
          {(
            <Box
              bg="text"
              sx={{
                height: '100%',
                boxSizing: 'border-box',
                overflow: 'scroll',
                overscrollBehavior: 'contain',
                width: '100vw',
                '> div': {
                  height: '100%',
                  backgroundImage:
                    'linear-gradient(90deg,rgba(0, 27, 255, 0.0) 40%, rgba(0, 27, 255, 0.05) 100%)',
                },
              }}
              applyTextColor
            >
              <CloseOnLargeScreens close={close} />
              <Wrap width="full">
                <Box
                  sx={{
                    height: '100%',
                    position: 'relative',
                    display: 'grid',
                    gridTemplateRows: 'auto 1fr auto',
                    gap: '48px',
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        position: 'absolute',
                        left: '-24px',
                        top: 0,
                        'svg > path': {
                          fill: 'white !important',
                        },
                      }}
                    >
                      <Logo isSlate={false} />
                    </Box>
                    <Inline
                      gap={['small', 'xlarge']}
                      alignY="center"
                      align="flex-end"
                      sx={{
                        height: 64,
                        '--logo-space': '96px',
                        width: 'calc(100% - var(--logo-space))',
                        ml: 'var(--logo-space)',
                      }}
                    >
                      <Box>
                        <LangSwitch />
                      </Box>
                      <CloseButton close={close} />
                    </Inline>
                  </Box>
                  <Stack
                    space={['small', 'small', 'large']}
                    sx={{ alignSelf: 'top' }}
                  >
                    {items.map(({ title, _key, items, NavLink }, index) =>
                      NavLink ? (
                        <React.Fragment key={_key}>
                          <NavLink>
                            <Anchor
                              variant="mobileNavItem"
                              onClick={() => {
                                close();
                              }}
                            >
                              <Box
                                sx={{
                                  // make link area larger
                                  display: 'inline-block',
                                  my: '-14px',
                                  py: '14px',
                                  width: '100%',
                                }}
                              >
                                {title}
                              </Box>
                            </Anchor>
                          </NavLink>
                          {
                            <Box
                              as="hr"
                              sx={{
                                backgroundColor: alpha('text', 0.2),
                                height: '1px',
                                border: 'none',
                              }}
                            />
                          }
                          {/* <Stack space={2}>
                            {items && items.length
                              ? items.map(({ title, _key, NavLink }) =>
                                  NavLink ? (
                                    <NavLink key={_key}>
                                      <Anchor
                                        variant="navItem"
                                        onClick={() => close()}
                                      >
                                        {title}
                                      </Anchor>
                                    </NavLink>
                                  ) : null,
                                )
                              : null}
                          </Stack> */}
                        </React.Fragment>
                      ) : null,
                    )}
                    <Inline align="flex-start">{button}</Inline>
                  </Stack>
                  <Inline
                    gap={['small', 'large']}
                    sx={{
                      mb: 'medium',
                      pb: 120,
                    }}
                  >
                    {legalNav?.items && legalNav.items.length
                      ? legalNav.items.map(({ title, NavLink, _key }) => (
                          <NavLink key={_key}>
                            <Anchor
                              variant="footerLegalLink"
                              onClick={() => close()}
                              sx={{
                                // make link area larger
                                display: 'inline-block',
                                m: '-14px',
                                p: '14px',
                              }}
                            >
                              {title}
                            </Anchor>
                          </NavLink>
                        ))
                      : null}
                  </Inline>
                </Box>
              </Wrap>
            </Box>
          )}
        </Draw>
      ) : null}
    </>
  );
}
