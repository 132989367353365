import * as React from 'react';
import { useNav } from '@lcifr/components/nav';
import { Hidden } from '@lcifr/components/hidden';
import { MainNavBar } from '../MainNavBar';
import { MainNavDrawer } from '../MainNavDrawer';

export function MainNav({ button }: { button: React.ReactNode }) {
  const nav = useNav('main');
  if (!nav || !nav.items) return null;

  return (
    <>
      <Hidden above="laptop">
        <MainNavDrawer items={nav.items} button={button} />
      </Hidden>
      <Hidden below="laptop">
        <MainNavBar items={nav.items} />
      </Hidden>
    </>
  );
}
