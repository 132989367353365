import { CookieBanner } from 'components/Cookiebanner';
import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { HeroProductContextProvider } from 'components/HeroProduct/HeroProduct.context';
import * as React from 'react';
// import { CookieConsentProvider } from '@use-cookie-consent/react';
// import { GoogleTagManager } from 'components/GoogleTagManager';
import { Matomo } from 'components/Matomo';

type LayoutProps = {
  children: React.ReactNode;
  header: any;
  footer: any;
  socialProfiles: any;
};

export function Layout({
  children,
  footer,
  socialProfiles,
  header,
}: LayoutProps) {
  return (
    <>
      {/* <CookieConsentProvider
        useCookieConsentHooksOptions={{
          defaultConsent: {},
        }}
      > */}
      {/* <GoogleTagManager /> */}
      <Matomo />
      <HeroProductContextProvider>
        <Header header={header} />
        {children}
        <Footer footer={footer} socialProfiles={socialProfiles} />
        <CookieBanner />
      </HeroProductContextProvider>
      {/* </CookieConsentProvider> */}
    </>
  );
}
