import * as React from 'react';
import { sx } from '@lcifr/sx';
import { Icon } from '@lcifr/components/icon';
import { useRawPageDocument, useRawPageShards } from '@lcifr/pages-base';
import { useRouter } from 'next/router';
import { Box } from '@lcifr/components/box';

export const Select = sx('select')({
  userSelect: 'none',
  cursor: 'pointer',
  font: 'Poppins, sans-serif',
  fontWeight: 600,
  fontSize: '12px',
  appearance: 'none',
  background: 'transparent',
  border: 'none',
  color: 'text',
});

interface LangSwitchProps {
  className?: string;
  sx?: any;
}

const setCookie = (locale) => {
  document.cookie = `NEXT_LOCALE=${locale}; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
};

function useSwitchLocale() {
  const { locale, locales, defaultLocale, push } = useRouter();
  const rawPageShards = useRawPageShards();
  const rawPageDocument = useRawPageDocument();
  const _id = rawPageDocument?._id ?? '0';

  const switchLocale = React.useCallback(
    (newLocale) => {
      let i18nId =
        locale === defaultLocale
          ? `i18n.${_id}.${newLocale}`
          : newLocale === defaultLocale
          ? _id.replace(/^i18n\./, '').replace(`.${locale}`, '')
          : _id.replace(`.${locale}`, `.${newLocale}`);

      const href = rawPageShards?.[i18nId]?.slug || '/';

      if (newLocale !== locale) {
        push(href, undefined, {
          locale: newLocale,
        });
        setCookie(newLocale);
      }
    },
    [_id, defaultLocale, locale, push, rawPageShards],
  );

  return { locale, locales, switchLocale };
}

export const LangSwitch = ({ className, sx }: LangSwitchProps) => {
  const { locales, locale, switchLocale } = useSwitchLocale();
  // console.log(locales);
  return (
    <Box
      sx={{
        display: 'flex',
        flexAlign: 'center',
        ...sx,
      }}
      className={className}
    >
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <Icon
          name="world"
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 2,
            height: 24,
            width: 23,
            svg: { height: 24, width: 23 },
          }}
        />
        <Select
          sx={{
            paddingLeft: 28,
            paddingTop: '6px',
            paddingBottom: '6px',
            position: 'relative',
            zIndex: 3,
          }}
          value={locale}
          onChange={(e) => {
            switchLocale(e.target.value);
            e?.target?.blur();
          }}
        >
          {locales?.map((localeItem, i) => (
            <option key={`locale-${i}`} value={localeItem}>
              {localeItem.toUpperCase()}
            </option>
          ))}
        </Select>
      </Box>
    </Box>
  );
};
