import * as React from 'react';
import { useLocalStorage } from 'usehooks-ts';

type HeroProductContextValue = {
  isActive: boolean;
  direction: string;
  setNewDirection: any;
  setActive(): void;
  setInactive(): void;
};

export const HeroProductContext = React.createContext<HeroProductContextValue>({
  isActive: false,
  direction: 'right',
  setNewDirection() {},
  setActive() {},
  setInactive() {},
});

export function HeroProductContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [isActive, setIsActive] = React.useState(false);
  const [direction, setDirection] = useLocalStorage('direction', 'right');
  const contextValue = React.useMemo(
    () => ({
      isActive,
      setActive() {
        setIsActive(true);
      },
      setInactive() {
        setIsActive(false);
      },
      direction,
      setNewDirection(direction) {
        setDirection(direction);
      },
    }),
    [isActive, setIsActive, direction, setDirection],
  );
  return (
    <HeroProductContext.Provider value={contextValue}>
      {children}
    </HeroProductContext.Provider>
  );
}
