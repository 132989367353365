import { sx } from '@lcifr/sx';
import { Icon } from '@lcifr/components/icon';
import { Drawer } from '@lcifr/components/drawer';

export const Draw = sx('div')({
  width: "100vw",
  height: "100vh",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 999,
  py: 0,
  '& > div': { height: '100%' },
  '& > div > div': { height: '100%' },
});

export const Burger = sx(Icon)({
  mt: '-4px',
  height: '24px',
  width: '32px',
  svg: {
    width: 'inherit',
    height: 'inherit',
  },
});

export const Close = sx(Icon)({
  mt: '-4px',
  height: '24px',
  width: '32px',
  svg: {
    width: 'inherit',
    height: 'inherit',
  },
});
