import * as React from 'react';
import { NavItem, DropdownNavContainer } from '@lcifr/components/nav';
import { Anchor } from '@lcifr/components/anchor';

type MainNavBarItemProps = NavItem & {};

export function MainNavBarItem({ title, NavLink }: MainNavBarItemProps) {
  if (!NavLink) return null;
  return (
    <DropdownNavContainer>
      <NavLink>
        <Anchor variant="navItem" sx={{ mr: [5, 5, 7] }}>
          {title}
        </Anchor>
      </NavLink>
    </DropdownNavContainer>
  );
}
