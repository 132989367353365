import { useEffect } from 'react';

export interface ScrollPosition {
  x: number;
  y: number;
}

const isBrowser = typeof window !== `undefined`;

function getScrollPosition(): ScrollPosition {
  return isBrowser
    ? { x: window.pageXOffset, y: window.pageYOffset }
    : { x: 0, y: 0 };
}

export function useScroll(
  {
    onScroll,
    active,
  }: {
    onScroll: (scrollPosition: ScrollPosition) => void;
    active?: boolean;
  },
  deps: React.DependencyList = [],
) {
  useEffect(() => {
    let requestRunning: number | null = null;
    function handleScroll() {
      if (isBrowser && requestRunning === null) {
        requestRunning = window.requestAnimationFrame(() => {
          onScroll(getScrollPosition());
          requestRunning = null;
        });
      }
    }

    if (isBrowser && active) {
      window.addEventListener('scroll', handleScroll, { passive: true });
      handleScroll();
      return () => {
        window.removeEventListener('scroll', handleScroll);
        if (requestRunning) {
          window.cancelAnimationFrame(requestRunning);
        }
      };
    }
  }, [onScroll, active, ...deps]);
}
