import * as React from 'react';
import { Header as HeaderComponent } from '@lcifr/components/header';
import { Wrap } from '@lcifr/components/wrap';
import { Box } from '@lcifr/components/box';
import { Button } from '@lcifr/components/button';
import { useScroll } from 'utils/hooks/useScroll';
import { MainNav } from 'components/MainNav';
import { Logo } from 'components/Logo';
import { LangSwitch } from 'components/LangSwitch';
import { LinkStudio } from '@lcifr/components/link';
import { Hidden } from '@lcifr/components/hidden';

type HeaderProps = {
  header: any;
};

export function Header({ header }: HeaderProps) {
  const [isTop, setIsTop] = React.useState(false);
  useScroll({
    onScroll: (scrollPosition) => {
      setIsTop(scrollPosition.y >= 1 ? true : false);
    },
    active: true,
  });

  const link = header?.link;
  const linkText = header?.linkText;

  const handleScrollClick = (id: string) => {
    /**
     * using scrollIntoView instead of css:`scroll-behaviour: smooth` because of
     * https://github.com/vercel/next.js/issues/12105#issuecomment-908317568
     */

    if (typeof document !== 'undefined') {
      const element = document.getElementById(id.replace('#', ''));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  const headerButton = linkText ? (
    <Button
      onClick={() => {
        handleScrollClick('#contact');
      }}
    >
      {linkText}
    </Button>
  ) : link && linkText ? (
    <LinkStudio link={link}>
      <Button>{linkText}</Button>
    </LinkStudio>
  ) : null;

  const hasNoTopBackground =
    typeof header?.hasNoTopBackground === 'undefined' ||
    header?.hasNoTopBackground === false
      ? false
      : true;
  const hasBackground = hasNoTopBackground ? isTop : true;

  return (
    <HeaderComponent
      position="fixed"
      sx={{
        width: '100%',
        position: 'fixed',
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          backgroundColor: 'background',
          transform: hasBackground ? 'translateY(0)' : 'translateY(-100%)',
          transition: 'all 0.2s ease-out',
        },
      }}
    >
      <Wrap>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            position: 'relative',
            height: [64, 64, 96],
            paddingLeft: isTop ? 0 : 36,
            transition: '0.2s ease-out padding',
            '@media (max-width: 1300px)': {
              paddingLeft: 0,
            },
            '@media (min-width: 1900px) and (max-width: 2075px)': {
              paddingLeft: 0,
            },
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              right: ['auto', 'auto', '100%'],
              left: [-24, -24, 'auto'],
              top: 0,
              '@media (max-width: 1300px)': {
                left: -24,
                right: 'auto',
              },
              '@media (min-width: 1900px) and (max-width: 2075px)': {
                left: -24,
                right: 'auto',
              },
            }}
          >
            <Logo isSlate={isTop} />
          </Box>
          <MainNav button={headerButton} />
          <Hidden below="laptop">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <LangSwitch sx={{ mr: 3 }} />
              {headerButton}
            </Box>
          </Hidden>
        </Box>
      </Wrap>
    </HeaderComponent>
  );
}
