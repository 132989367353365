import Script from 'next/script';
import { FC } from 'react';

export const triggerMatomoEvent = (name, data) => {
  if (typeof window !== 'undefined') {
    window._mtm = window._mtm || [];
    window._mtm.push({
      event: name,
      eventData: data,
    });
  }
};

export const Matomo: FC = () => {
  return (
    <>
      <Script id="matomo" strategy="afterInteractive">
        {`
          var _paq = window._paq = window._paq || [];
          /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          (function() {
            var u="https://${process.env.NEXT_PUBLIC_MATOMO_URL}.matomo.cloud/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '1']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src='//cdn.matomo.cloud/${process.env.NEXT_PUBLIC_MATOMO_URL}.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);
          })();
        `}
      </Script>
    </>
  );
};
