import * as React from 'react';
import { Footer as FooterComponent } from '@lcifr/components/footer';
import { Box } from '@lcifr/components/box';
import { sx } from '@lcifr/sx';
import { Wrap as LuciferWrap } from '@lcifr/components/wrap';
import { Icon as LuciferIcon } from '@lcifr/components/icon';
import { usePortableNodes } from '@lcifr/components/portable-nodes';
import { Stack } from '@lcifr/components/stack';
import { useRawI18n } from '@lcifr/pages';
import { Anchor, SalespartnerTeaser } from 'components/SalespartnerTeaser';
import { useNav } from '@lcifr/components/nav';
import { SocialProfiles } from '@lcifr/components/social-profiles';
import { Inline } from '@lcifr/components/inline';
import { LangSwitch } from 'components/LangSwitch';
import { alpha } from '@lcifr/theming';

export const WrapFilled = sx(LuciferWrap)({
  paddingLeft: '0 !important',
  paddingRight: '0 !important',
});

export const Wrap = sx(LuciferWrap)({});

export const Hr = sx('hr')({
  background: '#e8e8e8',
  border: 0,
  height: '1px',
  mt: ['large', 'large', 'xxlarge'],
});

export const GradientBox = sx(Box)({
  display: ['none', 'none', 'block'],
  position: 'absolute',
  top: 0,
  left: 0,
  width: '50%',
  height: '100%',
  background:
    'linear-gradient(180deg, rgba(15, 17, 37, 0.1) 0%, rgba(15, 17, 37, 0.03) 100%)',
});

export const ColorBox = sx(Box)({
  display: ['none', 'none', 'block'],
  position: 'absolute',
  bottom: 0,
  right: 0,
  width: '50%',
  height: '100%',
  bg: 'text',
});

export const Contact = sx(Box)({
  display: 'flex',
  flexDirection: ['column', 'column', 'row'],
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  py: [0, 0, 96],
});

export const ContactInfo = sx(Box)({
  display: 'flex',
  px: ['16px', '16px', 0],
  py: ['40px', '40px', 0],
  width: ['100%', '100%', '46%'],
  flexShrink: 0,
  p: {
    a: {
      fontSize: '13px',
      fontWeight: 600,
      textDecoration: 'underline',
      marginBottom: 2,
    },
  },
  background: [
    'linear-gradient(180deg, rgba(15, 17, 37, 0.1) 0%, rgba(15, 17, 37, 0.03) 100%)',
    'linear-gradient(180deg, rgba(15, 17, 37, 0.1) 0%, rgba(15, 17, 37, 0.03) 100%)',
    'none',
  ],
});

export const ContactSales = sx(Box)({
  color: 'background',
  px: ['16px', '16px', 0],
  py: ['40px', '40px', 0],
  width: ['100%', '100%', '45%'],
  bg: ['text', 'text', 'none'],
});

type FooterProps = {
  footer: any;
  socialProfiles: any;
};

export function Footer({ footer, socialProfiles }: FooterProps) {
  const nav = useNav('main');
  const navLegal = useNav('legal');

  const PortableNodes = usePortableNodes();
  const i18n = useRawI18n();
  const contactInfo = i18n?.contact?.info;
  const disableContact = footer?.disableContact;
  return (
    <>
      {!disableContact ? (
        <Box sx={{ position: 'relative' }}>
          <GradientBox />
          <ColorBox />
          <WrapFilled>
            <Contact id="contact">
              <ContactInfo>
                <LuciferIcon
                  sx={{
                    width: 84,
                    svg: { width: 'inherit' },
                    mr: [4, 4, 6],
                  }}
                  name="logo"
                />
                {contactInfo?.length && (
                  <PortableNodes blocks={contactInfo}>
                    {(children) => <Stack space={3}>{children}</Stack>}
                  </PortableNodes>
                )}
              </ContactInfo>
              <SalespartnerTeaser />
            </Contact>
          </WrapFilled>
        </Box>
      ) : null}
      <FooterComponent
        spaceT={['xxlarge']}
        spaceB={['large', 'large', 0]}
        position="static"
      >
        <Wrap>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            {(nav as any)?.items?.map((item, i) => (
              <Box
                key={`item-${i}`}
                sx={{ width: ['50%', '50%', 'auto'], pb: [6, 6, 0] }}
              >
                <Stack space={2}>
                  <item.NavLink>
                    <Anchor variant="footerHeading">{item.title}</Anchor>
                  </item.NavLink>
                  <Stack space={2}>
                    {(item as any)?.items?.map((subitem, j) => (
                      <subitem.NavLink key={`subitem-${j}`}>
                        <Anchor sx={{ fontSize: '15px' }}>
                          {subitem.title}
                        </Anchor>
                      </subitem.NavLink>
                    ))}
                  </Stack>
                </Stack>
              </Box>
            ))}
          </Box>
        </Wrap>
        <Hr />
        <Wrap>
          <Box
            sx={{
              height: ['auto', 'auto', '96px'],
              pt: ['large', 'large', 0],
              display: 'flex',
              flexDirection: ['column', 'column', 'row'],
              alignItems: ['flex-start', 'flex-start', 'center'],
              justifyContent: ['space-around', 'space-around', 'space-between'],
            }}
          >
            <Inline gap={5} sx={{ pb: ['medium', 'medium', 0] }}>
              {(navLegal as any)?.items?.map((item, i) => {
                return (
                  <item.NavLink key={`item-${i}`}>
                    <Anchor variant="footerLegalLink">{item.title}</Anchor>
                  </item.NavLink>
                );
              })}
            </Inline>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  position: 'relative',
                  flexShrink: 0,
                  pr: '24px',
                  mr: '24px',
                  '&:last-of-type': {
                    pr: 0,
                    mr: 0,
                    '&:after': {
                      display: 'none',
                    },
                  },
                  '&:after': {
                    content: '""',
                    position: 'absolute',
                    right: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: '1px',
                    height: '12px',
                    bg: alpha('text', 0.2),
                  },
                }}
              >
                <LangSwitch />
              </Box>
              <SocialProfiles
                profiles={socialProfiles}
                sx={{
                  fontSize: '20px',
                  color: 'primary',
                  path: {
                    fill: 'primary',
                  },
                }}
                gap={4}
              />
            </Box>
          </Box>
        </Wrap>
      </FooterComponent>
    </>
  );
}
