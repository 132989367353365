import * as React from 'react';
import { Box } from '@lcifr/components/box';
import { MainNavBarItem } from '../MainNavBarItem';
import { NavItem } from '@lcifr/components/nav';

type MainNavBarProps = {
  items: NavItem[];
};

export function MainNavBar({ items }: MainNavBarProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '@media (max-width: 1300px)': {
          pl: 92,
        },
        '@media (min-width: 1900px) and (max-width: 2075px)': {
          pl: 92,
        },
      }}
    >
      {items.map((navItem) => (
        <MainNavBarItem key={navItem._key} {...navItem} />
      ))}
    </Box>
  );
}
