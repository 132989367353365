import * as React from 'react';
import { Box } from '@lcifr/components/box';
import { Icon } from '@lcifr/components/icon';
import { Anchor } from '@lcifr/components/anchor';
import Link from 'next/link';

type LogoProps = {
  isSlate: boolean;
  sx?: any;
  className?: any;
};

export function Logo({ isSlate, className }: LogoProps) {
  return (
    <Link href="/" passHref legacyBehavior>
      <Anchor
        className={className}
        aria-label="Cetotec Logo"
        sx={{
          display: 'block',
          height: [64, 64, 96],
          width: 112,
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            flexBasis: '100%',
            transform: !isSlate && 'translateY(-50%)',
            transition: '0.2s ease-out transform',
          }}
        >
          <Icon
            name="slate"
            sx={{
              height: [64, 64, 96],
              width: 112,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              svg: { height: 38, width: 36 },
            }}
          />
          <Icon
            name="logo"
            sx={{
              height: [64, 64, 96],
              width: 112,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              svg: { height: 48, width: 64 },
            }}
          />
        </Box>
      </Anchor>
    </Link>
  );
}
